import * as React from 'react';
import {Grid, Icon, Segment, Menu, Button, Tab, TabProps,} from "semantic-ui-react";
import SppModal from "../../Common/SppModal";
import DetailTab from "./DetailTab";
import ChildrenTab from "./ChildrenTab";
import PageGroupService from "../../../services/page-group-service";
import PageService from "../../../services/page-service";
import {PageGroup, PortalProperties} from "../../../models/models";
import {emptyPageGroup} from "../../../util/constants";
import toast from "../../../util/toast";
import {info} from "../../../services/route-service";



interface PageProps {
    history: any,
    userCookie: any,
    match: any
    portalProperties: PortalProperties
}

interface PageState {
    modalOpen: boolean
    pageGroup: PageGroup
    buttonLoading: boolean
    tabIndex: any
}

export default class EditPageGroup extends React.Component<PageProps, PageState> {
     pageGroupClient: PageGroupService;
     pageClient: PageService;

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            modalOpen: false,
            pageGroup: emptyPageGroup,
            buttonLoading: false,
            tabIndex: 0
        };

        this.pageGroupClient = new PageGroupService(this.props.userCookie)
        this.pageClient = new PageService(this.props.userCookie)
    }

    //returns modal content for delete modal
    getModalContent = () => {
        return(
            {
                iconName: 'file excel outline',
                modalTitle: 'Delete Page',
                onClose: this.closeModal,
                modalBody:  <Grid id={'conf-info-content'}>
                    <Grid.Row>
                        <Grid.Column>
                            Are you sure you want to delete page group titled: {this.state.pageGroup.name}?
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'modal-action-button'}
                                    onClick={this.closeModal}
                                    color={'red'}
                                    id={'cm-negative-button'}
                                    content={'NO'}/>
                            <Button onClick={this.handleDeleteSubmit}
                                    color={'green'}
                                    id={'cm-positive-button'}
                                    className={'modal-action-button'}
                                    content={'YES'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>,
            }
        )
    };

    closeModal = () => {
        this.setState({modalOpen: false})
    };

    deleteOnClick = () => {
        this.setState({modalOpen: true})
    }

    //makes delete page axios call and returns action response. closes modal if successful
    handleDeleteSubmit = () => {
        this.setState({buttonLoading: true});
        this.pageGroupClient.deletePageGroup(parseInt(this.state.pageGroup.id.toString())).then(response => {
            this.setState({buttonLoading: false});
            if(response.ok)
                this.props.history.push(info.pageGroups.path)
            else
                toast.error(response.message);
        })
    };

    getPageGroup = () => {
        this.pageGroupClient.getPageGroupById(this.props.match.params.pageGroupId)
            .then((response: PageGroup) => {
                this.setState({pageGroup: response})
            })
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if(this.props.match.params.pageGroupId !== prevProps.match.params.pageGroupId)
        {
            this.pageGroupClient = new PageGroupService(this.props.userCookie)
            this.pageClient = new PageService(this.props.userCookie)
            this.getPageGroup()
            this.setState({tabIndex: 0})
        }
    }

    componentDidMount() {
        this.getPageGroup()
    }

    getPageGroupPanes = () => {
        return([
                {menuItem: 'Details',
                    render: () =>
                        <DetailTab
                            pageGroupId={this.props.match.params.pageGroupId}
                            history={this.props.history}
                            userCookie={this.props.userCookie}
                            pageGroupClient={this.pageGroupClient}
                            portalProperties={this.props.portalProperties}/>},
                {menuItem: 'Children Items',
                    render: () =>
                        <ChildrenTab
                            pageClient={this.pageClient}
                            history={this.props.history}
                            userCookie={this.props.userCookie}
                            parentPageGroupId={this.props.match.params.pageGroupId}
                            match={this.props.match}
                            pageGroupClient={this.pageGroupClient}/>}
            ]
        )
    };

    handleTabChange = (e: any, data: TabProps) => {
        this.setState({tabIndex: data.activeIndex})
    }

    render(){
        return(
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment.Group id={'cp-segment-group'}>
                            <Segment id={'cp-segment-header'}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column textAlign={'left'} width={6} verticalAlign={'middle'}>
                                            <Icon name={'edit outline'} size={'large'} /> Edit Page Group: {this.state.pageGroup.name}
                                        </Grid.Column>
                                        <Grid.Column textAlign={'right'} width={10}>
                                            <Menu
                                                compact={true}
                                                borderless={true}
                                                stackable={true}
                                                className={'ab-menu'}
                                                size={'small'}>
                                                <Menu.Item
                                                    onClick={this.deleteOnClick}
                                                    id={'aab-delete-click'}
                                                    className={'ab-menu-item'}
                                                    content={'Delete'}/>
                                            </Menu>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <Segment>
                                <Tab
                                    onTabChange={this.handleTabChange}
                                    activeIndex={this.state.tabIndex}
                                    panes={this.getPageGroupPanes()}/>
                            </Segment>
                        </Segment.Group>

                    </Grid.Column>
                </Grid.Row>
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.getModalContent()}/>
            </Grid>
        )
    }
}