import {
    ActionResponse, AppInfo,
    DropDownOption, FileBrowser,
    FsSecurity,
    Page,
    PageConfig,
    PageGroup,
    PageType,
    UaaApplication
} from "../models/models";

export const paginatedTableSizeOptions: DropDownOption[] = [
    {key:'5', value:5, text:'5 Rows'},
    {key:'10', value:10, text:'10 Rows'},
    {key:'15', value:15, text:'15 Rows'},
];

export const announcementColors: DropDownOption[] =[
    {key:'green', value:'green', text:'Green'},
    {key:'yellow', value:'yellow', text:'Yellow'}
];

export const genericError: ActionResponse<any> = {ok: false, message: 'Internal Error'};

export const modalTypes = {
    CREATE: 'C',
    UPDATE: 'U',
    DELETE: 'D'
};

export const pageTypeKey = {
    EMBEDDED: 'url',
    EXTERNAL: 'url',
    FILE_BROWSER: 'file-source-name',
    STATIC: 'view-name',
    FB_DESCRIPTION: 'fs_html',
    EMBEDDED_TITLE: 'show_embedded_title',
    TABLEAU: 'url',
    ALLOW_DASHBOARD: 'allow_in_user_dashboard',
    TABLEAU_CATEGORY: 'tableau_category',
    TABLEAU_VIEW_NAME: 'view-name'
};

export const filteredOutConfigKeys = [
    pageTypeKey.FB_DESCRIPTION,
    pageTypeKey.EMBEDDED_TITLE,
    pageTypeKey.ALLOW_DASHBOARD,
    pageTypeKey.TABLEAU_CATEGORY
]

export const emptyPageType: PageType = {
    id: '',
    name: ''
};

export const emptyParentPageGroup: PageGroup = {
    id: '',
    name: '',
    slug: '',
    asPage: false,
    depth: 0
};

export const emptyPageGroup: PageGroup = {
    id: '',
    name: '',
    slug: '',
    asPage: false,
    pageGroup: emptyParentPageGroup,
    depth: 0
};

export const emptyPage: Page = {
    title: '',
    slug: '',
    id: 0,
    activeFlag: false,
    isPublic: false,
    summary: '',
    pageType: emptyPageType,
    pageGroup: emptyPageGroup,
    depth: 0
};

export const emptyPageConfig: PageConfig = {
    pcValue: '',
    pcKey: '',
    page: emptyPage,
    favorite: false
};

export const emptyUaaApplication: UaaApplication = {
    id: 0,
    application: '',
    key: '',
    twoFactor: ''
};

export const emptyFsSecurity: FsSecurity = {
    id: 0,
    authzRole: '',
    authzAppKey: ''
};

export const emptyFileBrowser: FileBrowser = {
    fsId: 0,
    fsName: '',
    fsPath: '',
    fsSecId: 0,
    fsSecAuthAppName: '',
    fsSecAuthAppKey: '',
    fsSecAuthAppId: 0,
    fsSecAuthRole: ''
};

export const emptyAppInfo: AppInfo = {
    groupId: '',
    artifactId: '',
    version: '',
    environment: '',
    envDisplay: '',
    portalAddress: ''
};

export const emptyMenuOption = {name: '', slug: '', type: '', asPage: false, childList: []}

export const graphUpdateInterval = 300000;

export const chartName = {
    IM_GEN_MIX: 'generation-mix',
    IM_LOAD_FORECAST: 'forecast-vs-actual',
    IM_INTERCHANGE_TREND: 'interchange-trend',
    IM_LMP_TREND: 'hub-and-interface-prices',
    IM_ACE: 'ace-chart',
    WEIS_INTERCHANGE_TREND: 'weis-interchange-trend',
    WEIS_LOAD_FORECAST: 'weis-forecast-vs-actual',
    IM_GEN_MIX_YTD: 'generation-mix-ytd',
    IM_GEN_MIX_365: 'generation-mix-rolling-365',
    WEIS_GEN_MIX: 'weis-gen-mix'
}
