import AxiosService from "./axios-service";
import axios from "axios";


export default class ChartService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getChartData = (chartName: string): Promise<any> => {
        const url = `/chart-api/${chartName}/asChart`;
        return new Promise<any>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve({response: {datasets: [], label: []}, pieLabels: [], pieColors: []})})
        }));
    };

    getChartDownload = (chartName: string): Promise<any> => {
        const url = `/chart-api/${chartName}/asFile`;
        return new Promise<any>(((resolve) => {
            this.client.getAxiosInstance().get(url, {headers: {'Accept': '*/*'}, responseType: 'arraybuffer'})
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };
}

export const getTableauJWT = (xsrfToken: string): Promise<string> => {
    const url = `/api/tableau/`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<any>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {resolve(data)})
            .catch(() => {resolve('')})
    }));
}
